export function formatRangeValue(
  minValue: number,
  maxValue: number,
  unit = ""
) {
  const minFormated = minValue.toLocaleString();
  const maxFormated = maxValue.toLocaleString();
  // Handle different cases based on the provided values
  if (minValue === 0 && maxValue === 0) {
    return unit + "0"; // Return empty space if both are 0
  } else if (minValue === 0) {
    return unit + `${maxFormated}`; // Only maxValue is displayed if minValue is 0
  } else if (maxValue === 0) {
    return unit + `${minFormated}`; // Only minValue is displayed if maxValue is 0
  } else if (minValue === maxValue) {
    return unit + `${maxFormated}`; // Only maxValue is displayed if minValue is equals maxValue
  } else {
    return `${unit + minFormated} - ${unit + maxFormated}`; // Return the formatted range
  }
}
