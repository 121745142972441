import { useEffect, useState } from "react";

export const tailwindScreens = {
  sx: "640px",
  sm: "768px",
  md: "992px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

type Breakpoint = keyof typeof tailwindScreens;

/*const isXSmallScreen = useMediaQuery('sx');
const isSmallScreen = useMediaQuery('sm');
const isMediumScreen = useMediaQuery('md');
const isLargeScreen = useMediaQuery('lg');
const isXLargeScreen = useMediaQuery('xl');
const is2XLargeScreen = useMediaQuery('2xl');*/

const useMediaQuery = (breakpoint: Breakpoint): boolean => {
  const [isMatch, setIsMatch] = useState<boolean>(false);

  useEffect(() => {
    const query = `(max-width: ${tailwindScreens[breakpoint]})`;
    const mediaQueryList = window.matchMedia(query);

    const documentChangeHandler = () => setIsMatch(mediaQueryList.matches);

    mediaQueryList.addEventListener("change", documentChangeHandler);

    // Set the initial value
    setIsMatch(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener("change", documentChangeHandler);
    };
  }, [breakpoint]);

  return isMatch;
};

export default useMediaQuery;
